import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Markdown from "markdown-to-jsx"
import "../assets/css/cta.scss"
import { Link } from "gatsby"

const CTA = ({ data, color, side, main }) => {
  let filling = (
    <button className={color ? color : ""}>{data.buttonText || ""}</button>
  )
  let template = filling
  if (data.link && data.link.url != "") {
    return <Link to={data.link.url}>{filling}</Link>
  } else if (data.externalLink != "") {
    return (
      <a href={data.externalLink} target="_blank" rel="noopener nofollow">
        {filling}
      </a>
    )
  }
  return <Fragment />
}

CTA.defaultProps = {
  data: {},
}

CTA.propTypes = {
  data: PropTypes.object,
}

export default CTA
